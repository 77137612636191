<!-- 文件中文名: 在线支付页面 -->
<template>
    <div class="pay-online app-container">
        <el-form ref="payOnlineForm" :model="PayOnlineForm" label-position="right" label-width="auto" size="small"
                 style="text-align: left">
            <div style="width: 50%;margin: 0 auto">
                <!--在线支付-->
                <h3>{{ $t('online.payment') }}</h3>
                <!--订单编号-->
                <el-form-item :label="$t('order.no')">
                    {{ PayOnlineForm.orderNo }}
                </el-form-item>
                <!--在线支付价格-->
                <el-form-item v-if="companyCode === 'TRA'" :label="$t('menu.onlinePayment')+ $t('pmProductList.price')">
                    {{ PayOnlineForm.onlinePaylocal }} {{$symbol()}}
                </el-form-item>
                <!--总价格-->
                <el-form-item :label="$t('order.total.price')">
                    {{ PayOnlineForm.payAmount }} W$
                </el-form-item>
                <!-- 土耳其在线支付 内容 -->
                <div v-if="companyCode === 'TRA'">
                    <!-- 持卡人姓 -->
                    <el-form-item :label="$t('Cardholder.surname')+(':')" required>
                        <el-input v-model="PayOnlineForm.surname" />
                    </el-form-item>
                    <!-- 持卡人姓名 -->
                    <el-form-item :label="$t('Cardholder.full_name')+(':')" required>
                        <el-input v-model="PayOnlineForm.nameOnCard" />
                    </el-form-item>
                    <!-- 卡号 -->
                    <el-form-item :label="$t('title.cardNo')+(':')" required>
                        <el-input v-model="PayOnlineForm.cardAsn" />
                    </el-form-item>
                    <!-- 卡CVC -->
                    <el-form-item :label="$t('Card.cvc')+(':')" required>
                        <el-input v-model="PayOnlineForm.cardCvc" />
                    </el-form-item>
                    <!-- 持卡人邮箱 -->
                    <el-form-item :label="$t('Cardholder.mailbox')+(':')" required>
                        <el-input v-model="PayOnlineForm.email" />
                    </el-form-item>
                    <!-- 卡到期年份 -->
                    <el-form-item :label="$t('Card.expiration_year')+(':')" required>
                      <el-select v-model="PayOnlineForm.cardExpireYear" placeholder="">
                        <el-option v-for="item in YearList" :key="item" :label="item" :value="item.toString().substr(2,2)"/>
                      </el-select>
                    </el-form-item>
                    <!-- 卡到期月份 -->
                    <el-form-item :label="$t('Card.expiration_month')+(':')" required>
                        <el-select v-model="PayOnlineForm.cardExpireMonth" placeholder="">
                            <el-option
                              v-for="item in MonthList"
                              :key="item"
                              :label="item"
                              :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <el-form-item>
                    <el-button
                            :loading="btnLoading"
                            class="filter-item"
                            type="primary"
                            @click="doPayOnline"
                    >
                        <!--支付-->
                        {{ $t('Common.pay') }}
                    </el-button>
                </el-form-item>
            </div>
        </el-form>
        <div v-show="false">
            <form id="onlineForm2" name="pay_form" :action="onlineForm.postUrl"
                  method="post">
                <input v-if="companyCode === 'LTV'" type="hidden" name="data" :value="onlineForm.data"/>
                <input v-if="companyCode === 'LTV'" type="hidden" name="sign" :value="onlineForm.sign"/>
                <input v-if="companyCode === 'TRA'" type="text" name='parameters' :value="onlineForm.parameters"/>
            </form>
        </div>
    </div>
</template>

<script>

import {memberOnlinePay} from "@/api/rus/api"
import {mapGetters} from "vuex";
export default {
    name: 'PayOnline',
    data() {
        return {
            YearList: [],
            MonthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            orderNo: '',
            payMoney: '',
            PayOnlineForm: {},
            PayOnlineData: {},
            onlineForm: {
                postUrl: null,
                data: null,
                sign: null,
                parameters: null
            },
            btnLoading: false,
        }
    },
    computed: {
        ...mapGetters([
            'language',
            'userCode',
            'username',
            'companyCode',
        ]),
    },
    created() {
        if (!sessionStorage.getItem("PayOnlineData")) {
            console.log('PayOnlineData不存在!')
            this.$router.go(-1)
        }
        this.PayOnlineData = JSON.parse(sessionStorage.getItem("PayOnlineData"))
        this.PayOnlineForm.orderNo = this.PayOnlineData.orderNo
        this.PayOnlineForm.payAmount = this.PayOnlineData.payAmount
        this.PayOnlineForm.keyongyue = this.PayOnlineData.keyongyue
        this.PayOnlineForm.onlinePaylocal = this.PayOnlineData.onlinePaylocal
        this.PayOnlineForm.id = this.PayOnlineData.id
    },
    mounted() {
        for (let i = 2023; i < 2101; i++) {
            this.YearList.push(i)
        }
    },
    methods: {
        doPayOnline() {
            this.btnLoading = true
            if (this.companyCode === 'TRA') {
                console.log(this.PayOnlineForm, 'this.PayOnlineForm.surname')
                if (this.PayOnlineForm.surname === '' || this.PayOnlineForm.surname === undefined) {
                    this.$message.info(this.$t('po.setInfo') + this.$t('Cardholder.surname')) // 请填写持卡人姓
                    this.btnLoading = false
                    return false;
                }
                if (this.PayOnlineForm.nameOnCard === '' || this.PayOnlineForm.nameOnCard === undefined) {
                    this.$message.info(this.$t('po.setInfo') + this.$t('Cardholder.full_name')) // 请填写持卡人姓名
                    this.btnLoading = false
                    return false;
                }
                if (this.PayOnlineForm.cardAsn === '' || this.PayOnlineForm.cardAsn === undefined) {
                    this.$message.info(this.$t('po.setInfo') + this.$t('title.cardNo')) // 请填写卡号
                    this.btnLoading = false
                    return false;
                }
                if (this.PayOnlineForm.cardCvc === '' || this.PayOnlineForm.cardCvc === undefined) {
                    this.$message.info(this.$t('po.setInfo') + this.$t('Card.cvc')) // 请填写卡CVC
                    this.btnLoading = false
                    return false;
                }
                if (this.PayOnlineForm.email === '' || this.PayOnlineForm.email === undefined) {
                    this.$message.info(this.$t('po.setInfo') + this.$t('Cardholder.mailbox')) // 请填写持卡人邮箱
                    this.btnLoading = false
                    return false;
                }
                if (this.PayOnlineForm.cardExpireMonth === '' || this.PayOnlineForm.cardExpireMonth === undefined) {
                    this.$message.info(this.$t('po.setInfo') + this.$t('Card.expiration_month')) // 请填写卡到期月份
                    this.btnLoading = false
                    return false;
                }
                if (this.PayOnlineForm.cardExpireYear === '' || this.PayOnlineForm.cardExpireYear === undefined) {
                    this.$message.info(this.$t('po.setInfo') + this.$t('Card.expiration_year')) // 请填写卡到期年份
                    this.btnLoading = false
                    return false;
                }
            }
            memberOnlinePay({...this.PayOnlineForm}).then(res => {
                this.onlineForm.postUrl = res.data.postUrl
                this.onlineForm.data = res.data.data
                this.onlineForm.sign = res.data.sign
                this.onlineForm.parameters = res.data.data.replace(/\\"/g, '"')
                setTimeout(() => {
                    document.getElementById('onlineForm2').submit()
                    this.btnLoading = false
                }, 1000)
            }).catch(err => {
                console.error(err)
                this.btnLoading = false
            })
        }
    }
}

</script>

<style scoped>

</style>
